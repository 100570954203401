import Pusher from "pusher-js";

class PusherService {
  constructor() {
    this.pusher = null;
  }
  init() {
    Pusher.logToConsole = false;
    this.pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
      cluster: process.env.VUE_APP_PUSHER_CLUSTER,
      encrypted: true,
    });
  }
  subscribe(channelName) {
    if (!this.pusher) {
      console.log("Pusher is not initialized");
      return;
    }
    return this.pusher.subscribe(channelName);
  }
  unsubscribe(channelName) {
    if (!this.pusher) {
      console.error("Pusher is not initialized.");
      return;
    }
    this.pusher.unsubscribe(channelName);
  }
}

const pusherService = new PusherService();
export default pusherService;