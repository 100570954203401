<template>
    <!-- Chart widget with bar chart Start-->
    <div class="row">
        <div class="col-md-12 box-col-12">
            <div class="card o-hidden">
                <div class="card-header">
                    <h4>Monthly History</h4>
                </div>
                <div class="bar-chart-widget">
                    <div class="bottom-content card-body">
                        <div class="row">
                            <div class="col-12">
                                <apexchart height="350" type="bar" :options="chartOptions" :series="series">
                                </apexchart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
    <!-- Chart widget with bar chart Ends-->
</template>

<script>
var primary = localStorage.getItem("primary_color") || "#7366ff";
var secondary = localStorage.getItem("secondary_color") || "#f73164";

import {
    chart
} from '../../data/comon'
export default {
    props: {
        data: {
            type: Array,
            default: () => []
        },
    },
    computed: {
        chartOptions() {
            return {
                ...this.options,
                xaxis: {
                    ...this.options.xaxis,
                    categories: this.data.months || this.options.xaxis.categories
                }
            };
        },
        series() {
            return [
                {
                    name: "Total Complaints",
                    data: this.data.total.length ? this.data.total : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                },
                {
                    name: "Resolved Complaints",
                    data: this.data.resolved.length ? this.data.resolved : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                },
                {
                    name: "Pending Complaints",
                    data: this.data.pending.length ? this.data.pending : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                },
            ];
        }
    },


    data() {
        return {
            progress1: chart.progress1,
            progress2: chart.progress2,
            progress3: chart.progress3,
            progress4: chart.progress4,
            progress5: chart.progress5,
            options: {
                chart: {
                    width: 855,
                    height: 350,
                    type: "bar",
                    toolbar: {
                        show: false,
                    },
                },
                legend: {
                    show: true,
                },
                colors: ["#7366ff", "#51bb25", "#f73164"],
                dataLabels: {
                    enabled: false,
                },
                plotOptions: {
                    bar: {
                        endingShape: "rounded",
                        radius: 10,
                        borderRadius: 8,
                        horizontal: false,
                        columnWidth: "30%",
                    },
                },
                stroke: {
                    show: true,
                    colors: ["transparent"],
                    curve: "smooth",
                    lineCap: "butt",
                },
                xaxis: {
                    categories: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                    ],
                },
                yaxis: {
                    title: {
                        text: "Complaints",
                    },
                },
                fill: {
                    colors: ["#7366ff", "#51bb25", "#f73164"],
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val + " complaints";
                        },
                    },
                },
                grid: {
                    yaxis: {
                        show: false,
                    },
                    padding: {
                        left: 0,
                        right: 0,
                    },
                },
            },
        }
    }
}
</script>
