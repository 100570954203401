<template>
  <div class="header-wrapper row m-0">
    <SearchBar />
    <Logo />
    <div class="left-header col-xxl-5 col-xl-6 col-lg-5 col-md-4 col-sm-3 p-0">
      <swiper :slidesPerView="1" :autoplay="{ delay: 2500, disableOnInteraction: false }" :modules="modules"
        class="notification-slider" direction="vertical">
        <swiper-slide class="h-100">
          <router-link class="mb-0" to="/">
            <img src="../../assets/images/logo/logo-green.png" alt />
          </router-link>
        </swiper-slide>
      </swiper>
    </div>
    <div class="nav-right col-xxl-7 col-xl-6 col-md-7 col-8 pull-right right-header p-0 ms-auto">
      <ul class="nav-menus">
        <li>
          <span class="header-search"><svg @click="search_open()">
              <use href="@/assets/svg/icon-sprite.svg#search"></use>
            </svg>
          </span>
        </li>
        <Notifications />
        <Profile />
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper";
import "swiper/css";
import Language from './language';
import Notifications from './notifications.vue';
import Mode from './mode';
import Cart from './cart';
import Profile from './profile';
import Logo from './logo.vue';
import SearchBar from './search';
export default {
  components: {
    Language, Notifications, Mode, Cart, Profile, Logo, Swiper, SwiperSlide, SearchBar,
  },
  data() {
    return {
      bookmark: false,
      dataArray: ["sdada", "abbjhbdjsa", "gsydsaydgajd", "sgdjadgj", "asad", "sadsd", "asdue", "udasdgad", "uahdkahsdk", "umair", "auhdkaskdh"],
    };
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.searchData,
      megamenuItems: (state) => state.menu.megamenu,
      searchOpen: (state) => state.menu.searchOpen,
    }),
  },
  methods: {
    search_open() {
      this.$store.state.menu.searchOpen = true;
    },
    bookmark_open() {
      this.bookmark = !this.bookmark;
    },
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
};
</script>

<style scoped>
.left-header img {
  margin-top: 0px  !important;
  height: 33px  !important;
}
</style>