<template>
    <div class="container">
        <loading :active="loading" :is-full-page="true" loader="bars"></loading>
        <div class="row">
            <div class="col-md-6 mt-5 mx-auto">
                <router-link to="/complaints" class="btn btn-sm btn-primary mb-3">Back</router-link>
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Add Complaint</h4>
                    </div>
                    <div class="card-body">
                        <!-- <button type="button" class="btn btn-sm btn-primary" @click="toggleAddUser">
                            <span v-if="isAddUser">Remove</span>
                            <span v-else>Add User</span>
                        </button> -->
                        <form @submit.prevent="submitUser" ref="addUser" v-if="isAddUser" class="mt-2">
                            <div class="form-group row">
                                <div class="col-md-6">
                                    <label>Full Name <span class="text-danger">*</span></label>
                                    <span class="text-danger d-block" v-if="errors.fname">
                                        {{ errors.fname && errors.fname.length > 0 ? errors.fname[0] : '' }}
                                    </span>
                                    <input type="text" class="form-control form-control-sm mb-3" required
                                        v-model="admin.f_name" />
                                </div>
                                <div class="col-md-6">
                                    <label>Professtion</label>
                                    <input type="text" class="form-control form-control-sm mb-3"
                                        v-model="admin.profession" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Phone</label>
                                <span class="text-danger d-block" v-if="errors.phone">
                                    {{ errors.phone && errors.phone.length > 0 ? errors.phone[0] : '' }}
                                </span>
                                <input type="text" class="form-control form-control-sm mb-3" v-model="admin.phone"
                                    @keypress="isNumber($event)" />
                            </div>
                            <div class="form-group">
                                <label>Cnic <span class="text-danger">*</span></label>
                                <span class="text-danger d-block" v-if="errors.cnic">
                                    {{ errors.cnic && errors.cnic.length > 0 ? errors.cnic[0] : '' }}
                                </span>
                                <input type="text" class="form-control form-control-sm mb-3" v-model="admin.cnic"
                                    @keypress="isNumber($event)" required />
                            </div>
                            <div class="form-group">
                                <label>Email <span class="text-danger">*</span></label>
                                <span class="text-danger d-block" v-if="errors.email">
                                    {{ errors.email && errors.email.length > 0 ? errors.email[0] : '' }}
                                </span>

                                <input type="email" class="form-control form-control-sm mb-3" required
                                    v-model="admin.email" />
                            </div>
                            <div class="form-group">
                                <label>Password <span class="text-danger">*</span></label>
                                <span class="text-danger d-block" v-if="errors.password">
                                    {{ errors.password && errors.password.length > 0 ? errors.password[0] : '' }}
                                </span>
                                <span class="text-danger d-block" v-if="errors.password">
                                    Password is required
                                </span>
                                <input type="password" class="form-control form-control-sm mb-3" required
                                    v-model="admin.password" />
                            </div>
                            <div class="row">
                                <div class="col-md-12 mt-3">
                                    <button type="submit" class="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                        <form @submit.prevent="submit" ref="addUser" v-else>
                            <div v-if="errors && errors['Incomplete Profile']" class="alert alert-danger mt-3">
                                Please complete profile first.
                            </div>
                            <div class="form-group row">
                                <div class="form-group mt-2">
                                    <label>User<span class="text-danger">*</span></label>
                                    <select class="form-control form-control-sm mb-3" v-model="complaint.user_id"
                                        required>
                                        <option value="">Select...</option>
                                        <option v-for="user in users" :value="user.id">
                                            {{ user?.profile?.name }} - CNIC: {{ user.cnic }}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label>Title <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control form-control-sm mb-3" required
                                        v-model="complaint.title" placeholder="Enter the title" />
                                </div>
                                <div class="form-group">
                                    <label>Address <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control form-control-sm mb-3" required
                                        v-model="complaint.location" placeholder="Enter the Address" />
                                </div>
                                <div>
                                    <div>
                                        <label class="mt-2">Document (Image, Video, PDF)</label>
                                    </div>
                                    <input ref="fileInput" @change="uploadPDF" type="file" accept="image/*,video/*,.pdf"
                                        class="form-control" />
                                </div>
                                <div class="mt-3"
                                    v-if="mediaType == 'image' || mediaType == 'video' || mediaType == 'application/pdf'"
                                    style="display: flex; justify-content: space-between; align-items: center;">
                                    <div></div>
                                    <div @click="removeFile">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16"
                                            style="color: #ff432a;">
                                            <path fill-rule="evenodd"
                                                d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zM4.354 4.354a.5.5 0 0 1 .708 0L8 7.293l3.938-3.939a.5.5 0 0 1 .708.708L8.707 8l3.939 3.938a.5.5 0 1 1-.708.708L8 8.707l-3.938 3.939a.5.5 0 1 1-.708-.708L7.293 8 3.354 4.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </div>
                                </div>
                                <img v-if="mediaType == 'image'" :src="filePerview" alt="Image Preview"
                                    class="mt-2 w-100" />
                                <video class="mt-2  w-100" id="video-preview" controls :src="filePerview"
                                    v-if="mediaType == 'video'" />
                                <div v-if="mediaType == 'application/pdf' && fileUrl" class="mt-2">
                                    <a class="mt-2 link-with-underline" :href="fileUrl" target="_blank">View
                                        Document</a>
                                </div>
                                <div class="form-group mt-3">
                                    <label>Detail <span class="text-danger">*</span></label>
                                    <textarea class="form-control form-control-sm mb-3" required
                                        v-model="complaint.complaint_detail" placeholder="Enter the complaint details"
                                        style="height: 150px;"></textarea>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 mt-3">
                                    <button type="submit" class="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toRaw } from "vue";
import isNumber from "@/helpers/helpers";

export default {
    mixins: [isNumber],
    data() {
        return {
            complaint: {
                complaint_detail: null,
                title: null,
                user_id: "",
                document: "",
                location: "",
            },
            admin: {
                f_name: null,
                l_name: null,
                phone: null,
                email: null,
                password: null,
                officer_type: "",
                cnic: null,
                district_id: "",
                createdBy: localStorage.getItem("uid"),
            },
            users: [],
            errors: [],
            loading: false,
            fileUrl: null,
            filePerview: "",
            mediaType: "",
            isAddUser: false
        };
    },
    mounted() {
        this.fetchUsers();
    },
    methods: {
        uploadPDF(event) {
            const file = event.target.files[0];
            const fileType = file.type;

            if (fileType.startsWith('image/')) {
                this.handleMedia(file);
            } else if (fileType.startsWith('video/')) {

                this.handleMedia(file);
            } else if (fileType === 'application/pdf') {
                this.handlePDF(file);
            } else {

                console.log('Unsupported file type');
            }
        },
        handleMedia(file) {
            const reader = new FileReader();
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.complaint.document = e.target.result;
                    this.mediaType = this.complaint.document.startsWith('data:image') ? 'image' : this.complaint.document.startsWith('data:video') ? 'video' : null;
                    this.filePerview = e.target.result;
                };
                reader.readAsDataURL(file);
            }

        },
        handlePDF(file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64String = btoa(
                    new Uint8Array(reader.result)
                        .reduce((data, byte) => data + String.fromCharCode(byte), '')
                );
                this.mediaType = "application/pdf";
                this.complaint.document = `data:application/pdf;base64,${base64String}`;
                console.log("sss", this.complaint.document);
                const blob = new Blob([reader.result], { type: 'application/pdf' });
                this.fileUrl = URL.createObjectURL(blob);
            };

            reader.readAsArrayBuffer(file);
        },
        removeFile() {
            this.$refs.fileInput.value = "";
            this.mediaType = "";
            this.complaint.document = "";
            this.filePerview = "";
            this.fileUrl = "";
        },
        toggleAddUser() {
            this.isAddUser = !this.isAddUser;
        },
        submit() {
            this.loading = true;
            this.errors = [];
            const data = toRaw(this.complaint);
            data.mediaType = this.mediaType;
            this.$store
                .dispatch("complaint/store", { data })
                .then((response) => {
                    this.loading = false;
                    if (response.success == true) {
                        this.$toast.show("Successfuly added.", {
                            theme: "outline",
                            position: "top",
                            type: "success",
                            duration: 5000,
                        });
                        this.errors = [];
                        Object.keys(this.complaint).forEach((key) => (this.complaint[key] = ""));
                        this.$refs.fileInput.value = "";
                        this.mediaType = "";
                        this.filePerview = "";
                    }
                })
                .catch((e) => {
                    this.loading = false;
                    this.errors = e.error;
                });
        },
        submitUser() {
            this.loading = true;
            this.errors = [];
            const data = toRaw(this.admin);
            this.$store
                .dispatch("users/store", { data })
                .then((response) => {
                    this.loading = false;
                    this.$toast.show("Successfuly added.", {
                        theme: "outline",
                        position: "top",
                        type: "success",
                        duration: 5000,
                    });
                    Object.keys(this.admin).forEach((key) => (this.admin[key] = null));
                    this.fetchUsers();
                    this.complaint.user_id = response.data.id;
                    this.isAddUser = false;
                    this.errors = [];
                })
                .catch((e) => {
                    this.loading = false;
                    this.errors = e.error;
                });
        },
        fetchUsers() {
            this.loading = true;
            this.$store
                .dispatch("users/getAll")
                .then((response) => {
                    this.users = response.data;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    console.log(e.error);
                });
        },
    },
};
</script>