<template>
  <form class="form-inline search-full col" action="#" method="get" @submit.prevent=""
    :class="searchOpen ? 'open' : ''">
    <div class="form-group w-100">
      <div class="Typeahead Typeahead--twitterUsers">
        <div class="u-posRelative">
          <input class="demo-input Typeahead-input form-control-plaintext w-100" type="text" @input="debouncedSearch()"
            v-model="terms" placeholder="Search Complaint .." name="q" title="" autofocus />
          <div class="spinner-border Typeahead-spinner" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <vue-feather class="close-search" type="x" @click="search_close()"></vue-feather>
        </div>
        <div :class="searchResult ? 'Typeahead-menu is-open' : 'Typeahead-menu'" v-if="menuItems.length">
          <div class="ProfileCard u-cf" v-for="(menuItem, index) in menuItems" :key="index">

            <router-link :to="`/complaint/edit/${menuItem.encrypted_id}`" @click.native="search_close">
              Title: {{ menuItem.title }} | Reference: {{ menuItem.referenceNo ?? 'Not Assigned yet' }} | User Name: {{
              menuItem.user_profile?.name }}
            </router-link>


          </div>
        </div>
        <div :class="searchResultEmpty ? 'Typeahead-menu is-open' : 'Typeahead-menu'">
          <div class="tt-dataset tt-dataset-0">
            <div class="EmptyMessage">
              Your search turned up 0 results. Opps There are no result found.
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
var body = document.getElementsByTagName('body')[0];
import { menuItems } from '@/data/menu';
import { mapState } from 'vuex';
export default {
  name: 'SearchBar',
  data() {
    return {
      terms: '',
      searchResult: false,
      searchResultEmpty: false,
      menuItems: [],
      debouncedSearch: null,
    }
  },
  computed: {
    ...mapState({
      searchOpen: (state) => state.menu.searchOpen
    }),
  },
  created() {
    this.debouncedSearch = this.debounce(this.searchDataWithDebounce, 300)
  },

  methods: {
    debounce(func, wait) {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(this, args);
        }, wait);
      };
    },
    searchDataWithDebounce() {
      this.terms ? this.addFix() : this.removeFix();
      const search = this.terms;
      this.$store
        .dispatch("complaint/searchComplaints", { search })
        .then((response) => {
          console.log("complaints", response.data);
          this.menuItems = response.data;
          if (!this.menuItems.length) this.searchResultEmpty = true;
          else this.searchResultEmpty = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },

    search_open() {
      this.$store.state.menu.searchOpen = true;
    },
    search_close() {
      this.$store.state.menu.searchOpen = false;
      this.removeFix()
    },
    searchterm() {
      this.$store.dispatch('menu/searchTerm', this.terms);
    },
    addFix() {
      body.classList.add('offcanvas');
      this.searchResult = true;
    },
    setActiveLink(item) {
      this.$store.dispatch('menu/setNavActive', item);
      this.$router.replace(item.path)
    },
    removeFix() {
      body.classList.remove('offcanvas');
      this.searchResult = false;
      this.terms = '';
    },
  },
};
</script>
