<template>
  <div>
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row" v-if="searchBar">
      <div class="col-md-4">
        <div class="input-group mb-2">
          <input type="text" class="form-control form-control-sm" placeholder="Type here to search..."
            v-model="searchValue" @keyup.enter="search" />
          <span class="input-group-text" id="basic-addon2" @click="search"><i class="fa fa-search"></i></span>
          <span class="input-group-text" id="basic-addon2" @click="clearSearch"><i class="icon-close"></i></span>
        </div>
      </div>
    </div>
    <div class="datatableData">
      <table class="table">
        <thead class="table-light">
          <tr>
            <th v-for="column in columns">{{ column.text }}</th>
            <th class="text-end">
              <span class="">Action</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in items.data" :key="i">
            <td v-for="column in columns"
              :style="{ backgroundColor: item.color === 'red' ? '#ff432a' : '', color: item.color === 'red' ? 'white' : '' }">
              <template
                v-if="column.thirdValue && item[column.value] && item[column.value][column.secondValue] && item[column.value][column.secondValue][column.thirdValue]">
                {{ item[column.value][column.secondValue][column.thirdValue] }}
              </template>
              <template v-else-if="column.secondValue && item[column.value] && item[column.value][column.secondValue]">
                {{ item[column.value][column.secondValue] }}
              </template>
              <template v-else-if="item[column.value]">
                {{ item[column.value] }}
              </template>
              <template v-else>

              </template>
            </td>
            <td>
              <div class="d-flex justify-content-end">
                <div v-if="isBlock" class="form-check form-switch">
                  <input class="form-check-input p-0 me-2" type="checkbox" style="height: 18px; width: 42px;"
                    role="switch" id="flexSwitchCheckChecked">
                  <label for="flexSwitchCheckChecked" class="mt-1 me-2">Block</label>
                </div>
                <button v-if="isChangePassword" class="btn p-0 me-2" @click="changePassword(item)">
                  <i class="fa fa-key text-primary" style="font-size: 18px"></i>
                </button>

                <button class="btn  me-2 p-0 d-flex align-items-baseline" @click="openItem(item)" v-if="isViewAble">
                  <i class="fa fa-eye text-primary me-1" style="font-size: 18px"></i>
                </button>
                <button class="btn me-2 p-0 d-flex align-items-baseline" @click="openItem(item)" v-if="viewDataforms">
                  <i class="fa fa-eye text-primary me-1" style="font-size: 18px"></i>
                </button>

                <button class="btn me-2 p-0" style=" background-color: #0468B1 !important;
                  color: #fff;
                  padding: 0px 10px !important;" @click="closeEoComplaint(item)"
                  v-if="isClose && role == 'DC' && item.status == 2">
                  <span>Close</span>
                </button>

                <button class="btn me-2 p-0" style=" background-color: #0468B1 !important;
                  color: #fff;
                  padding: 0px 10px !important;" @click="closeEoComplaint(item)"
                  v-if="isClose && role == 'EO' && item.status == 0">
                  <span>Close</span>
                </button>



                <button class="btn me-2 p-0 d-flex align-items-baseline" type="button" title="Block"
                  @click="setSelectedAdminId(item.id, item.isBlock, item)" v-if="isAdminBlock && item.isBlock == 0"
                  data-bs-toggle="modal" data-bs-target="#blockAdminModel">
                  <i class="fa fa-ban text-danger me-1" style="font-size: 18px"></i>
                </button>


                <button class="btn  me-2 p-0 d-flex align-items-baseline" type="button" title="Unblock"
                  @click="setSelectedAdminId(item.id, item.isBlock)" v-if="isAdminBlock && item.isBlock == 1">
                  <i class="fa fa-check text-success" style="font-size: 18px"></i>
                </button>



                <button class="btn  me-2 p-0 d-flex align-items-baseline" title="Edit" @click="openItem(item)"
                  v-if="editable">
                  <i class="fa fa-edit text-primary me-1" style="font-size: 18px"></i>
                </button>


                <button class="btn me-2 p-0" @click="openChat(item)" style="
                  background-color: #0468B1 !important;
                  color: #fff;
                  padding: 0px 10px !important;
                " v-if="ischat">
                  Chat
                </button>

                <button class="btn me-2 p-0 d-flex align-items-baseline" type="button" data-bs-toggle="modal"
                  :data-bs-target="'#deleteModal' + index" @click="selectItem(item)" v-if="deleteable">
                  <i class="fa fa-trash-o text-danger me-1" style="font-size: 18px"></i>
                </button>

                <button class="btn me-2 p-0 d-flex align-items-baseline" type="button" data-bs-toggle="modal"
                  v-bind:data-bs-target="'#showEditSOPModel'" @click="selectSop(item)" v-if="isupdate">
                  <i class="fa fa-edit text-primary me-1" style="font-size: 18px"></i>
                </button>

                <button class="btn  me-2 p-0 d-flex align-items-baseline" type="button" data-bs-toggle="modal"
                  v-bind:data-bs-target="'#AddEOModel'" @click="selectItem(item)" v-if="isAddEO">
                  <i class="fa fa-edit text-secondary me-1" style="font-size: 18px"></i>
                </button>

                <button class="btn me-2 p-0" @click="openItem(item)" style="
              background-color: #0468B1 !important;
                  color: #fff;
                  padding: 0px 10px !important;
                " v-if="viewable">
                  View Details
                </button>

                <button class="btn me-2 p-0" style="
              background-color: #0468B1 !important;
                  color: #fff;
                  padding: 0px 10px !important;" @click="redirectBackCMC(item)" v-if="cmsComliant">
                  View Detail
                </button>

                <button class="btn me-2 p-0" @click="openEODetail(item)" style="
                  background-color: #0468B1 !important;
                  color: #fff;
                  padding: 0px 10px !important;
                " v-if="eoComplaintDetail">
                  Details
                </button>

              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>


    <nav aria-label="Page navigation example">
      <ul class="pagination pagination-sm justify-content-end mt-3">
        <li class="page-item" v-for="page in items.links" :key="page.id">
          <a href="javascript:void(0);" class="page-link" :class="{ active: page.active }" v-if="page.url != null"
            @click="movePages(page.url)"><span v-html="page.label"></span></a>
        </li>
      </ul>
    </nav>


    <div class="modal fade mt-5" id="blockAdminModel" tabindex="-1" aria-labelledby="deleteModalLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Are you sure you want to block {{ name }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form>

              <div class="form-group mt-2">
                <label for="comment">Reason <span class="text-danger">*</span></label>
                <div v-if="reasonfield">
                  <span class="text-danger">Reason is Required</span>
                </div>
                <textarea v-model="blockreason" class="form-control" id="comment" rows="3" placeholder="Enter a reason"
                  required></textarea>
              </div>

              <div class="container mt-3">
                <button type="button" class="btn btn-primary me-3" data-bs-dismiss="modal"
                  @click="blockUser">Block</button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit SOP Modal -->
    <div class="modal fade mt-5" id="AddEOModel" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add EO</h5>
            <button type="button" class="btn-close" id="submitEobutton" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitEO">
              <div class="form-group">
                <label for="selectOfficer">Select Officer:</label>
                <select class="form-control" id="selectOfficer" name="admin_id" required>
                  <option value="">Select EO</option>
                  <option v-for="eo in eoAdmins" :value="eo.admin.id">{{ eo.admin.email }}</option>
                </select>
              </div>

              <div class="form-group mt-2">
                <label for="days">Days:</label>
                <input type="number" class="form-control" id="days" name="days" placeholder="Enter number of days"
                  required>
              </div>

              <div class="form-group mt-2">
                <label for="comment">Comment:</label>
                <textarea class="form-control" id="comment" rows="3" name="comment"
                  placeholder="Enter comment"></textarea>
              </div>

              <button type="submit" class="btn btn-primary mt-3">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Edit SOP Modal -->
    <div class="modal fade mt-5" id="showEditSOPModel" tabindex="-1" aria-labelledby="deleteModalLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Edit SOP</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form>
              <div class="mb-3">
                <label for="moduleName" class="form-label">Name of Module</label>
                <input type="text" class="form-control" id="moduleName" v-model="sop.name"
                  placeholder="Enter module name">
              </div>

              <div class="mb-3">
                <label for="days" class="form-label">Days</label>
                <input type="number" class="form-control" id="days" v-model="sop.days"
                  placeholder="Enter number of days">

              </div>

              <div class="mb-3">
                <label for="selectField" class="form-label">Select Form</label>
                <select class="form-select" id="selectField" v-model="sop.form_id">
                  <option disabled value="">Select form</option>
                  <option v-for="form in forms" :key="form.id" :value="form.id">{{ form.title }}</option>
                </select>
              </div>
              <button type="button" class="btn btn-primary" @click="updateSop">Update</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete Modal -->
    <div class="modal fade" :id="'deleteModal' + index" tabindex="-1" aria-labelledby="deleteModalLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <p>Are you sure?</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">
              No
            </button>
            <button type="button" class="btn btn-sm btn-primary" data-bs-dismiss="modal" @click="deleteItem">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>












  </div>
</template>

<script>
import router from "@/router";
import { toRaw } from "vue";


export default {
  props: {
    columns: [],
    items: [],
    forms: [],
    eoAdmins: [],
    module: null,
    isChangePassword: false,
    type: null,
    index: 1,
    searchBar: false,
    editable: false,
    deleteable: false,
    viewable: false,
    pagination: false,
    isupdate: false,
    isViewAble: false,
    isAddEO: false,
    ischat: false,
    isBlock: false,
    isAdminBlock: false,
    viewDataforms: false,
    isClose: false,
    eoComplaintDetail: false,
    cmsComliant: false,
    isSearch: { type: Boolean, default: true },
  },
  data() {
    return {
      role: localStorage.getItem('role'),
      searchValue: "",
      pageSize: 10,
      currentPage: 1,
      totalPages: 1,
      loading: false,
      selectedItem: null,
      selectedBlockUserId: "",
      reasonfield: false,
      blockreason: null,
      closeEO: false,
      sop: {
        name: "",
        days: "",
        form_id: "",
      },
      searchValue: "",
      name: "",
    };
  },

  created() {
    if (this.items !== undefined) {
      if (this.items.length > this.pageSize) {
        this.totalPages = this.items.length / this.pageSize;
      }
    }
  },
  methods: {
    changePassword(item) {
      router.push({ path: `/${this.module}/changePassword/${item.encrypted_id}` });
    },
    selectItem(item) {
      this.selectedItem = item;
    },
    // movePages(amount) {
    //   this.currentPage = amount;
    // },
    nextPage() {
      if (this.currentPage * this.pageSize < this.items.length)
        this.currentPage++;
    },
    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    openItem(item) {
      if (item.parent_id) {
        router.push({
          path: `/${this.module}/edit/${item.id}/${item.parent_id}`,
        });
      } else {
        if (this.module == "users") {
          router.push({
            path: `/${this.module}/edit/${item.encrypted_id}`,
            query: { type: this.type },
          });
        } else {
          router.push({ path: `/${this.module}/edit/${item.encrypted_id}` });
        }
      }
    },
    openChat(item) {
      router.push({ path: `/comments/add/${item.id}` });
    },
    openEODetail(item) {
      router.push({ path: `/eo-comlaint-detail/${item.encrypted_id}` });
    },
    deleteItem() {
      this.loading = true;
      const id = toRaw(this.selectedItem.id);
      this.$store
        .dispatch(`${this.module}/delete`, { id })
        .then(() => {
          this.loading = false;
          this.$toast.show("Successfuly deleted", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.$emit("deleted");
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    updateSop() {
      this.$emit("sopUpdate", this.sop);
    },
    selectSop(item) {
      this.sop = item;
    },
    setSelectedAdminId(id, isBlock, item = null) {
      if (item != null) {
        this.name = item.f_name || item.admin?.f_name || item.profile.name || "";
      }
      if (isBlock == 1) {
        const data = {
          id: id,
          reason: this.blockreason,
          type: 'unblock'
        };
        this.$emit("blockUser", data);
      } else {
        this.selectedBlockUserId = id;
      }
    },
    blockUser() {
      if (this.blockreason === null || this.blockreason === "") {
        this.reasonfield = true;
        return;
      }
      const data = {
        id: this.selectedBlockUserId,
        reason: this.blockreason,
        type: 'block'
      };
      this.blockreason = "";
      this.reasonfield = false;
      this.$emit("blockUser", data);
    },
    redirectBackCMC(item) {
      this.$emit("details", item);
    },
    submitEO(event) {
      const formData = new FormData(event.target);
      const btn = document.getElementById('submitEobutton');
      const eoData = {
        'eo_id': formData.get('admin_id'),
        'days': formData.get('days'),
        'comment': formData.get('comment'),
        'complaint_id': this.selectedItem.id,
      };
      this.$emit("addEo", eoData);
      event.target.reset();
      btn.click();
    },
    closeEoComplaint(item) {
      this.$emit("closeEoComplaint", item.id);
    },
    search() {
      this.$emit("search", this.searchValue);
    },
    clearSearch() {
      this.searchValue = "";
      this.$emit("clear", this.searchValue);
    },
    movePages(url) {
      if (process.env.NODE_ENV && process.env.NODE_ENV == "production") {
        url = url.replace("http://", "https://");
      }
      this.$emit("nextPage", url, this.searchValue);
    },

  },
};
</script>

<style scoped>
.btn:hvoer {
  background-color: transparent !important;
}
</style>
