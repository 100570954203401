<template>
  <li class="profile-nav onhover-dropdown pe-0 py-0">
    <div class="media profile-media">
      <img class="img-responsive" :src="profileImage" alt="" />
      <div class="media-body">
        <span>{{ name }}</span>
        <p class="mb-0 font-roboto">
          {{ showRole() }} <i class="middle fa fa-angle-down"></i>
        </p>
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div">
      <li>
        <router-link to="/admins/profile"><vue-feather type="user"></vue-feather><span>Profile </span></router-link>
      </li>
      <li>
        <router-link to="/admins/changePassword"><vue-feather type="user"></vue-feather><span>Change Password
          </span></router-link>
      </li>
      <li>
        <a @click="logout"><vue-feather type="log-in"></vue-feather><span>Log out</span></a>
      </li>
    </ul>
  </li>
</template>

<script>

export default {
  name: 'Profile',
  data() {
    return {
      name: null,
      admin: [],
      profileImage: "",
    };
  },
  mounted() {
    this.name = localStorage.getItem("f_name");
    this.profileImage = localStorage.getItem('profile_image');

  },
  methods: {
    logout() {
      localStorage.clear();
      this.$router.replace("/auth/login");
    },
    showRole() {
      const roles = {
        'DC': 'DC',
        'inquiry-officer': 'IO',
        'Department-Head': 'DH',
        'Department-FocalPerson': 'DF',
        'Department-Unit': 'DU',
        'EO': 'EO',
        'Registrar': 'Registrar'
      };
      const userRole = localStorage.getItem("role");
      return roles[userRole] || 'Admin';
    },
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("admins/getSingle", { id })
        .then((response) => {
          this.admin = response.data;
          this.profileImage = this.admin.profile.image_url;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>

<style scoped>
.img-responsive {
  width: 35px;
  max-width: 100%;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}
</style>